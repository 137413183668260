import React from 'react';

const TextAreaForm = (props: any) => {
	return (
		<div>
			<label className="label">{props.label}</label>
			<textarea className="form-control textareaSize" rows={props.rows} readOnly value={props.getValue} />
		</div>
	);
};

export default TextAreaForm;
