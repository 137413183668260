import xmlFormatter from "xml-formatter";
const line: string = "==============================================";
const defaultLine: string = "----";

export function onChangeTextFieldNumber(e: any) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
        return +e.target.value;
    } else {
        return 0;
    }
}

export function verifyTextField(textField: number, textFieldDescription: string): boolean {
    if (textField === 0 || textField === null) {
        alert(`You need put the ${textFieldDescription} `);
        return false;
    }
    return true;
}

export function getResponseAPI(responseBackend: any): string {
    let dataXml = "";
    if (responseBackend != null) {
        responseBackend.data.forEach((element: any, index: number) => {
            if (element.status !== 200 && element.status !== 201 && element.status !== 500) {
                dataXml = dataXml + "Response Server: " + (index + 1) + line + `\n` + element.status + `\n` + element.message + `\n\n`;
            } else {
                dataXml = dataXml + "Response Server: " + (index + 1) + line + `\n` + xmlFormatter(element.data) + `\n\n`;
            }
        });
        return dataXml;
    } else {
        return `${defaultLine}`;
    }
}

export function getUnitAvailability(responseBackend: any): string {
    if (responseBackend != null) {
        return JSON.stringify(responseBackend.data.payload, undefined, 4);
    } else {
        return "...";
    }
}

export function getRequestMiddleware(responseBackend: any): string {
    let requestXml = "";
    if (responseBackend != null) {
        responseBackend.data.forEach((element: any, index: number) => {
            requestXml = requestXml + "Request Sent: " + (index + 1) + line + `\n` + xmlFormatter(element.request) + `\n\n`;
        });
        return requestXml;
    } else {
        return `${defaultLine}`;
    }
}
