import React from 'react';

const TextField = (props: any) => {
	return (
		<div className=" form-group p-2 m-0 d-flex flex-column justify-content-end ">
			<label className="label ">{props.label}</label>
			<input
				type="text"
				name={props.name}
				className="form-control textStyle"
				value={props.stateValue}
				onChange={props.onChange}
				disabled={props.disabled}
			/>
		</div>
	);
};

export default TextField;
