import React, {useState} from 'react';
import SelectForm from "./SelectForm";
import TextField from "./TextField";
import {
    getUnitAvailability,
    onChangeTextFieldNumber
} from "../functions/Handlers";
import Button from "./ButtonForm";
import {apiCreated} from "../functions/ApiConnect";
import TextAreaForm from "./TextAreaForm";

const UnitAvailabilityForm = () => {
    const [unitIdNumber, setUnitIDNumber] = useState(0);
    const optionsSelector = [{id: '1', text: 'Dynamodb'}, {id: '2', text: 'RDS'}];
    const [optionDB, setOptionDB] = useState(1)
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    //setting data
    let fake: string = "...";
    const [response, setResponse] = useState(fake);
    const api = apiCreated

    function onChangeSelector(e: React.ChangeEvent<HTMLSelectElement>) {
        if (e.target.value === optionsSelector[0].id) {
            setOptionDB(1);
        } else {
            setOptionDB(2);
        }
        setResponse(fake);
        setOptionDB(+e.target.value);
    }

    function verify(): boolean {
        if (unitIdNumber === 0) {
            alert('You need put the Unit ID ');
            return false;
        }
        return true
    }

    async function copyToClipboard() {
        await navigator.clipboard.writeText(response)
    }

    async function submitRequest() {
        let res;
        if (verify()) {
            if (!disableSubmitButton) {
                setDisableSubmitButton(true);
                setResponse(fake);
                try {
                    if (optionDB === 1) {
                        res = await api.get('/api/unit-id?unitId=' + unitIdNumber);
                    } else {
                        res = await api.get('/api/unitMessages?unitId=' + unitIdNumber);
                    }
                } catch (err) {
                    res = await err.response;
                }
                setDisableSubmitButton(false);
                setResponse(await getUnitAvailability(res));
            }
        }
    }

    return (
        <div>
            <form>
                <div className="container">
                    <div className="row mb-12">
                        <SelectForm
                            label={'Database'}
                            name={'databaseSelector'}
                            handle={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeSelector(e)}
                            options={optionsSelector}
                        />
                    </div>

                    <div className="row mb-12">
                        <TextField
                            label={'Unit ID'}
                            name={'unitIdNumber'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setUnitIDNumber(onChangeTextFieldNumber(e))}
                            stateValue={unitIdNumber}
                            disabled={false}
                        />
                        <Button submit={submitRequest} description={'Get Availability'}
                                isLoading={disableSubmitButton}/>

                        <Button submit={copyToClipboard} description={'Copy To Clipboard'}
                                isLoading={false}/>
                    </div>
                    <div className="row mb-12">
                        <div className="col-10">
                            <TextAreaForm
                                label={'Availability JSON'}
                                rows={20}
                                getValue={response}
                            />
                        </div>
                    </div>
                </div>

            </form>
        </div>
    );
};

export default UnitAvailabilityForm;
