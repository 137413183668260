import React, { useState } from 'react';

import TextField from './TextField';
import Button from './ButtonForm';
import DatePickerCalendar from './DatePickerCalendar';
import TextAreaForm from './TextAreaForm';
import SelectForm from './SelectForm';

import {getRequestMiddleware, getResponseAPI, onChangeTextFieldNumber} from '../functions/Handlers';
import {apiCreated} from "../functions/ApiConnect";


const FormReservation = () => {
	const [ unitChannelID, setUnitChannelID ] = useState(0);
	const [ checkin, setCheckin ] = useState(new Date(Date.now()));
	const [ checkout, setCheckout ] = useState(new Date(Date.now()));
	const [ channel, setChannel ] = useState(2);
	const [ numberGuest, setNumberGuest ] = useState(2);
	const [ numberRooms, setNumberRooms ] = useState(1);
	const [ idProperty, setIdProperty ] = useState(0);
	const [ idPropertyDisabled, setIdPropertyDisabled ] = useState(false);
	const [ disableSubmitButton, setDisableSubmitButton ] = useState(false);

	//setting data
	let fake: any;
	const [ responseBackend, setResponseBackend ] = useState(fake);
	const today: Date = new Date(Date.now());
	const maxDate: Date = new Date(Date.now());
	maxDate.setDate(maxDate.getDate() + 730);
	const optionsSelector = [ { id: '2', text: 'Booking' }, { id: '3', text: 'Expedia' } ];


	const api = apiCreated;

	interface IReservations {
		data: {
			unitChannelID: number[];
			checkIn: Date;
			checkOut: Date;
			channelID: number;
			guest: number;
			numberRooms: number[];
			idProperty: number;
		};
	}

	function handleChangeCheckin(date: any) {
		setCheckin(date);
		setCheckout(date);
	}

	function handleChangeCheckout(date: any) {
		setCheckout(date);
	}

	function onChangeChannelID(e: React.ChangeEvent<HTMLInputElement>) {
		const re = /^[0-9\b]+$/;
		if (e.target.value === '' || re.test(e.target.value)) {
			setUnitChannelID(+e.target.value);
			setResponseBackend(fake);
		}
	}

	function onChangeSelector(e: React.ChangeEvent<HTMLSelectElement>) {
		if (e.target.value === optionsSelector[0].id) {
			setIdPropertyDisabled(false);
		} else {
			setIdPropertyDisabled(true);
			setIdProperty(0);
		}
		setChannel(+e.target.value);
	}

	function getDataReservation(): IReservations {
		return {
			data: {
				unitChannelID: [unitChannelID],
				checkIn: checkin,
				checkOut: checkout,
				channelID: channel,
				guest: numberGuest,
				numberRooms: [numberRooms],
				idProperty: idProperty
			}
		};
	}

	function verifyData(): boolean {
		if (unitChannelID === 0) {
			alert('You need put  the Unit Channel ID ');
			return false;
		}

		if (checkin === null) {
			alert('You need put the Checkin ');
			return false;
		}

		if (checkout === null) {
			alert('You need put the Checkout ');
			return false;
		}

		if (numberGuest === 0) {
			alert('You need put the Number of Guests ');
			return false;
		}

		if (numberRooms === 0) {
			alert('You need put the Number of Rooms ');
			return false;
		}

		if (channel === 2) {
			if (idProperty === 0) {
				alert('You need put the Id Property ');
				return false;
			}
		}
		return true;
	}

	async function submitRequest() {
		const reservations = getDataReservation();
		setResponseBackend(fake);
		if (verifyData()) {
			if (!disableSubmitButton) {
				let res;
				setDisableSubmitButton(true);
				try {
					res = await api.post('/api/reservation', { reservations });
				} catch (err) {
					res = await err.response;
				}
				setDisableSubmitButton(false);
				setResponseBackend(res);
			}
		}
	}

	return (
		<div>
			<form>
				<div className="container">
					<div className="row mb-4">
						<SelectForm
							label={'Channel'}
							name={'channelSelector'}
							handle={(e: React.ChangeEvent<HTMLSelectElement>) => onChangeSelector(e)}
							options={optionsSelector}
						/>
					</div>

					<div className="row mb-4">
						<TextField
							label={'Channel Unit ID'}
							name={'unitChannelID'}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeChannelID(e)}
							stateValue={unitChannelID}
							disabled={false}
						/>

						<DatePickerCalendar
							label={'Check-In'}
							selectedDate={checkin}
							handleDate={handleChangeCheckin}
							minDate={today}
							maxDate={maxDate}
						/>

						<DatePickerCalendar
							label={'Check-Out'}
							selectedDate={checkout}
							handleDate={handleChangeCheckout}
							minDate={checkin}
							maxDate={maxDate}
						/>
					</div>
					<div className="row mb-4">
						<TextField
							label={'Numbers Rooms'}
							name={'numberReservations'}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setNumberRooms(onChangeTextFieldNumber(e))}
							stateValue={numberRooms}
							disabled={false}
						/>
						<TextField
							label={'Numbers Guests'}
							name={'numberGuest'}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setNumberGuest(onChangeTextFieldNumber(e))}
							stateValue={numberGuest}
							disabled={false}
						/>
						<TextField
							label={'Id Property'}
							name={'idProperty'}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								setIdProperty(onChangeTextFieldNumber(e))}
							stateValue={idProperty}
							disabled={idPropertyDisabled}
						/>

						<Button submit={submitRequest} description={'Reservation'} isLoading={disableSubmitButton} />
					</div>

					<div className="row mb-4">
						<div className="col-md-6 ">
							<TextAreaForm
								label={'Request Sent'}
								rows={25}
								getValue={getRequestMiddleware(responseBackend)}
							/>
						</div>
						<div className="col-md-6 ">
							<TextAreaForm
								label={'Response API Integration'}
								rows={25}
								getValue={getResponseAPI(responseBackend)}
							/>
						</div>
					</div>
				</div>
			</form>

			<br />
		</div>
	);
};

export default FormReservation;
