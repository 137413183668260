import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerCalendar = (props: any) => {
	return (
		<div className="form-group p-2 m-0 d-flex flex-column justify-content-end">
			<label className="label ">{props.label}</label>
			<DatePicker
				dateFormat="dd/MM/yyyy"
				className="form-control textStyle"
				selected={props.selectedDate}
				onChange={props.handleDate}
				minDate={props.minDate}
				maxDate={props.maxDate}
			/>
		</div>
	);
};

export default DatePickerCalendar;
