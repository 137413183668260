import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";
import UnitAvailabilityForm from "./components/UnitAvailabilityForm";
import FormReservation from "./components/FormReservation";

export default function App() {
	return (
		<Router>
			<div>
				<nav className="navbar navbar-dark bg-dark">
					<ul className="nav">
						<li className="styleNav">
							<Link to="/" className="styleTextNav" >UI Reservation</Link>
						</li>
						<li className="styleNav">
							<Link to="/availability-unit" className="styleTextNav">Unit ID Availability</Link>
						</li>

					</ul>
				</nav>
				<Switch>
					<Route path="/availability-unit" >
						<UnitAvailabilityForm />
					</Route>
					<Route path="/">
						<FormReservation />
					</Route>

				</Switch>
			</div>
		</Router>
	);

}