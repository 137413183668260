import React from 'react';

const Button = (props: any) => {
	if (props.isLoading) {
		return (
			<div className="form-group p-2 m-0 d-flex flex-column justify-content-end float-right">
				<button
					className="btn btn-info buttonPrimaryStyle float-right"
					type="button"
					disabled={props.isLoading}
				>
					<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
					{props.description}
				</button>
			</div>
		);
	}
	return (
		<div className="form-group p-2 m-0 d-flex flex-column justify-content-end float-right">
			<button
				type="button"
				className="btn btn-info buttonPrimaryStyle float-right"
				onClick={props.submit}
				disabled={props.isLoading}
			>
				{props.description}
			</button>
		</div>
	);
};

export default Button;
